import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    name: '',
    type: 'text',
    resultType: 'text',
  },
  openForm: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'addColumn',
  initialState,
  reducers: {
    formInit: (state, action) => {
      state.data.name = action.payload.name
    },
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changeContentType: (state, action) => {
      state.data.type = action.payload.type
    },
    changeResultType: (state, action) => {
      state.data.resultType = action.payload.resultType
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload.openForm
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.addColumn
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectOpenForm = (state) => selectSlice(state).openForm

const { reducer, actions } = slice

export const {
  changeRequestStatus,
  formInit,
  changeResultType,
  changeContentType,
  resetForm,
  changeName,
  setOpenForm,
} = actions

export default reducer

import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMeeting } from '../../../../reducers/meetings/meeting-details'
import { MeetingStatuses } from '../../../../constants'
import Loader from '../../../common/loader/Loader'
import ViewSidebarV2 from './SidebarV2'
import { isJsonString } from '../../../../utils/json-processor'
import { jsonrepair } from 'jsonrepair'
import { cleanJsonString } from '../../../../utils/json-processor'
import OutputStructureV1_0 from './OutputStructureV1_0'
import OutputStructureV1_1 from './OutputStructureV1_1'

const statusesMessages = {
  [MeetingStatuses.AI_PROCESSING]: {
    title: 'This meeting is currently processing by our AI.',
    subtitle: 'Please wait, it can takes a few minutes.',
  },
  [MeetingStatuses.TRANSCRIPTION_FAILED]: {
    title: 'Transcription process failed',
    subtitle: 'Please contact our support team.',
  },
  [MeetingStatuses.WAITING_FOR_TRANSCRIPTION]: {
    title: 'This meeting will start processing soon',
    subtitle: 'Please wait, it can takes a few minutes.',
  },
  [MeetingStatuses.AI_PROCESSING_FAILED]: {
    title: 'Meeting processing failed',
    subtitle: 'Please contact our support team for help.',
  },
  [MeetingStatuses.READY_FOR_AI_PROCESSING]: {
    title: 'Meeting is waiting for processing',
    subtitle: 'Please wait.',
  },
  [MeetingStatuses.CLEARED]: {
    title: 'Meeting data was cleared',
    subtitle: 'All meeting data was cleared after analysis or expired.',
  },
}

export default function MeetingDetailsV2({ meeting }) {
  const storedMeeting = useSelector(selectMeeting)

  if (!meeting) {
    meeting = storedMeeting
  }

  const [selectedTaskUuid, selectTaskUuid] = useState(null)
  const [selectedTask, selectTask] = useState(null)

  useEffect(() => {
    if (selectedTaskUuid && meeting) {
      const selectedResult = meeting.processingResults.find((result) => result.task.uuid === selectedTaskUuid)
      selectTask(selectedResult.task)
    }
  }, [selectedTaskUuid])

  let sections = []
  if (meeting && meeting.uuid && selectedTaskUuid) {
    const json = meeting.processingResults.find((result) => result.task.uuid === selectedTaskUuid)

    if (json && json.result) {
      const jsonData = JSON.parse(json.result)
      const sectionsRawJson = jsonData.data || jsonData

      sections = sectionsRawJson.map((sectionJson) => {
        // Fix JSON if it is corrupted
        if (sectionJson.contentType === 'list' && typeof sectionJson.value === 'string') {
          let cleanedValue = cleanJsonString(sectionJson.value)
          if (!isJsonString(cleanedValue)) {
            cleanedValue = jsonrepair(cleanedValue)
          }

          if (isJsonString(cleanedValue)) {
            // Recursively check the parsed value
            sectionJson.value = JSON.parse(cleanedValue)
          } else {
            sectionJson.value = [
              { title: 'Data parsing error', content: 'The error occurs during the result preparation' },
            ]
          }

          return sectionJson
        }

        return sectionJson
      })
    }
  }

  return (
    <div>
      <div className={'my-4'}>
        <p className="text-sm font-semibold text-gray-500">{meeting.typeV2.name}</p>
        <div className="mt-2 text-sm text-gray-500 sm:col-span-2 whitespace-pre-line">{meeting.typeV2.description}</div>
      </div>
      <div className="relative pt-4 lg:flex lg:gap-x-16">
        {meeting.status === MeetingStatuses.READY && (
          <>
            <ViewSidebarV2 selectedTaskUuid={selectedTaskUuid} selectTask={selectTaskUuid} meeting={meeting} />
            <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">
              <div
                className={
                  'flex flex-row justify-between content-center items-center pb-8 mb-8 border-b border-gray-100'
                }
              >
                <div className={'flex flex-col'}>
                  <div className={'text-gray-700 text-xl font-bold'}>{selectedTask?.name}</div>
                  <div className={'text-gray-500 text-sm font-normal'}>{selectedTask?.description}</div>
                </div>
              </div>
              {(meeting.processingResultsVersion === '1.0' || !meeting.processingResultsVersion) && (
                <OutputStructureV1_0 sections={sections} />
              )}
              {meeting.processingResultsVersion === '1.1' && <OutputStructureV1_1 sections={sections} />}
            </div>
          </>
        )}
        {meeting.status !== MeetingStatuses.READY && (
          <div className="mt-12 w-full text-center">
            {(meeting.status === MeetingStatuses.AI_PROCESSING ||
              meeting.status === MeetingStatuses.WAITING_FOR_TRANSCRIPTION) && (
              <div className={'mx-auto w-12 mb-4'}>
                <Loader size={12} />
              </div>
            )}
            <h3 className="mt-2 text-sm font-semibold text-gray-900">{statusesMessages[meeting.status]?.title}</h3>
            <p className="mt-1 text-sm text-gray-500">{statusesMessages[meeting.status].subtitle}</p>
          </div>
        )}
      </div>
    </div>
  )
}

import { createSlice } from '@reduxjs/toolkit'
import { updateTaskContent } from '../../components/meeting/list/types/task/constructor/slice'
import { REQUEST_STATUSES } from '../reducer-helper'

const initialState = {
  data: {
    sections: [],
  },
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'taskConstructor',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateTaskContent.fulfilled, (state) => {
      state.status = REQUEST_STATUSES.DONE
    })
  },
  reducers: {
    initForm: (state, action) => {
      state.data.sections = action.payload.sections
    },
    addSection: (state, action) => {
      state.data.sections.push(action.payload.section)
    },
    renameSection: (state, action) => {
      state.data.sections[action.payload.index].name = action.payload.name
    },
    deleteSection: (state, action) => {
      state.data.sections.splice(action.payload.indexForDelete, 1)
    },
    changeTextSection: (state, action) => {
      state.data.sections[action.payload.index].data.assignment = action.payload.value
    },
    changeListSection: (state, action) => {
      state.data.sections[action.payload.index].data.assignment = action.payload.value
    },
    addColumn: (state, action) => {
      if (state.data.sections[action.payload.index].type === 'table') {
        state.data.sections[action.payload.index].data.cols.push(action.payload.column)
        state.data.sections[action.payload.index].data.rows.forEach((row) => {
          const colStructure = {
            assignment: '',
            type: action.payload.column.type,
          }
          if (action.payload.column.type === 'ai-task') {
            colStructure.resultType = action.payload.column.resultType
          }

          row.cells.push(colStructure)
        })
      }
    },
    updateColumn: (state, action) => {
      if (state.data.sections[action.payload.sectionIndex].type === 'table') {
        state.data.sections[action.payload.sectionIndex].data.cols[action.payload.colIndex] = action.payload.column
      }
    },
    addRow: (state, action) => {
      if (state.data.sections[action.payload.index].type === 'table') {
        state.data.sections[action.payload.index].data.rows.push(action.payload.row)
      }
    },
    updateCellContent: (state, action) => {
      if (state.data.sections[action.payload.sectionIndex].type === 'table') {
        if (!state.data.sections[action.payload.sectionIndex].data.rows[action.payload.rowIndex].cells) {
          const cells = [...state.data.sections[action.payload.sectionIndex].data.rows[action.payload.rowIndex]]
          state.data.sections[action.payload.sectionIndex].data.rows[action.payload.rowIndex] = { cells }
        }

        state.data.sections[action.payload.sectionIndex].data.rows[action.payload.rowIndex].cells[
          action.payload.colIndex
        ].assignment = action.payload.value
      }
    },
    deleteColumn: (state, action) => {
      if (state.data.sections[action.payload.sectionIndex].type === 'table') {
        state.data.sections[action.payload.sectionIndex].data.cols.splice(action.payload.indexForDelete, 1)
        state.data.sections[action.payload.sectionIndex].data.rows.forEach((row) => {
          row.cells.splice(action.payload.indexForDelete, 1)
        })
      }
    },
    deleteRow: (state, action) => {
      if (state.data.sections[action.payload.sectionIndex].type === 'table') {
        state.data.sections[action.payload.sectionIndex].data.rows.splice(action.payload.indexForDelete, 1)
      }
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.taskConstructor
export const selectData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
const { reducer, actions } = slice

export const {
  changeRequestStatus,
  resetForm,
  initForm,
  addColumn,
  updateColumn,
  deleteSection,
  deleteColumn,
  deleteRow,
  addRow,
  changeTextSection,
  changeListSection,
  addSection,
  updateCellContent,
  renameSection,
} = actions

export default reducer

import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Bars3CenterLeftIcon,
  BarsArrowDownIcon,
  PencilIcon,
  PlusIcon,
  TableCellsIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import {
  addRow,
  addSection,
  changeListSection,
  changeTextSection,
  deleteColumn,
  deleteRow,
  deleteSection,
  selectData,
  resetForm as resetConstructorForm,
  initForm as initConstructorForm,
  changeRequestStatus as changeConstructorRequestStatus,
  selectRequestStatus as selectConstructorRequestStatus,
} from '../../../../../../reducers/meeting-type-task/constructor'
import OutsideClickHandler from '../../../../../common/outside-click-handler/OutsideClickHandler'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { setOpenForm as setOpenUpdateCellContentForm } from '../../../../../../reducers/meeting-type-task/update-cell-content'
import { setOpenForm as setOpenUpdateColumnForm } from '../../../../../../reducers/meeting-type-task/update-column'
import UpdateCellContent from './update-cell-content/UpdateCellContent'
import { useParams } from 'react-router-dom'
import { selectTaskDetails } from '../../../../../../reducers/meeting-type-task/task-details'
import { updateTaskContent } from './slice'
import { selectTypeDetails } from '../../../../../../reducers/meeting-type/type-details'
import AddColumnForm from './add-column-form/AddColumnForm'
import { selectOpenForm as selectUpdateColumnOpenForm } from '../../../../../../reducers/meeting-type-task/update-column'
import UpdateColumnForm from './update-column-form/UpdateColumnForm'
import UnsavedDataNotification from './unsaved-data-notification/UnsaqvedDataNotification'
import { REQUEST_STATUSES } from '../../../../../../reducers/reducer-helper'
import Loader from '../../../../../common/loader/Loader'
import RenameSectionForm from './rename-section-form/RenameSectionForm'

export default function TaskConstructor() {
  const dispatch = useDispatch()

  const [showColumnDetails, setShowColumnDetails] = useState([])
  const [showTypes, setShowTypes] = useState(false)
  const [renamingSectionUuid, setRenamingSectionUuid] = useState(null)
  const [navigateConfirmModal, setNavigateConfirmModal] = useState(false)

  const { taskUuid } = useParams()

  const task = useSelector(selectTaskDetails)
  const type = useSelector(selectTypeDetails)
  const constructorRequestStatus = useSelector(selectConstructorRequestStatus)
  const showUpdateColumnForm = useSelector(selectUpdateColumnOpenForm)
  const { sections } = useSelector(selectData)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const addTextSection = () => {
    const structure = {
      type: 'text',
      uuid: (Math.random() + 1).toString(36).substring(2),
      name: '',
      data: { assignment: '' },
    }

    dispatch(addSection({ section: structure }))
    setShowTypes(false)
  }

  const addListSection = () => {
    const structure = {
      type: 'list',
      uuid: (Math.random() + 1).toString(36).substring(2),
      name: '',
      data: { assignment: '' },
    }

    dispatch(addSection({ section: structure }))
    setShowTypes(false)
  }
  const addTableSection = () => {
    const structure = {
      type: 'table',
      uuid: (Math.random() + 1).toString(36).substring(2),
      data: {
        cols: [],
        rows: [{ cells: [] }],
      },
    }

    dispatch(addSection({ section: structure }))
    setShowTypes(false)
  }

  const sectionTypes = [
    {
      id: 'text-section',
      name: 'Text',
      handler: addTextSection,
      icon: Bars3CenterLeftIcon,
    },
    {
      id: 'list-section',
      name: 'List',
      handler: addListSection,
      icon: BarsArrowDownIcon,
    },
    {
      id: 'table-section',
      name: 'Table',
      handler: addTableSection,
      icon: TableCellsIcon,
    },
  ]

  const deleteSectionProcess = (index) => {
    dispatch(deleteSection({ indexForDelete: index }))
  }

  const changeTextSectionTaskProcess = (event, index) => {
    dispatch(changeTextSection({ index, value: event.target.value }))
  }

  const changeListSectionTaskProcess = (event, index) => {
    dispatch(changeListSection({ index, value: event.target.value }))
  }

  const openUpdateCellContentForm = (sectionIndex, rowIndex, colIndex, colName, content) => {
    dispatch(
      setOpenUpdateCellContentForm({
        sectionIndex,
        rowIndex,
        colIndex,
        colName,
        content,
        openForm: true,
      }),
    )
  }

  const toggleColumnDetails = (event, sectionIndex, columnIndex) => {
    event.preventDefault()
    let newData = [...showColumnDetails]
    if (showColumnDetails.includes(`section-${sectionIndex}-col-${columnIndex}`)) {
      const indexForDelete = showColumnDetails.findIndex(
        (item) => item === `section-${sectionIndex}-col-${columnIndex}`,
      )
      if (indexForDelete !== -1) {
        newData.splice(indexForDelete, 1)
      }
    } else {
      newData.push(`section-${sectionIndex}-col-${columnIndex}`)
    }

    setShowColumnDetails(newData)
  }

  const addNewRowProcess = (event, sectionIndex) => {
    event.preventDefault()
    const cells = []

    const section = sections[sectionIndex]

    section.data.cols.forEach((col) => {
      const cell = {
        assignment: '',
        type: col.type,
      }
      if (col.type === 'ai-task') {
        cell.resultType = col.resultType
      }

      cells.push(cell)
    })

    dispatch(addRow({ index: sectionIndex, row: { cells } }))
  }

  const deleteColProcess = (event, sectionIndex, indexForDelete) => {
    event.preventDefault()
    dispatch(deleteColumn({ sectionIndex, indexForDelete: indexForDelete }))
  }

  const deleteRowProcess = (event, sectionIndex, indexForDelete) => {
    event.preventDefault()
    dispatch(deleteRow({ sectionIndex, indexForDelete: indexForDelete }))
  }

  useEffect(() => {
    return () => {
      dispatch(resetConstructorForm())
    }
  }, [])

  useEffect(() => {
    if (task) {
      dispatch(resetConstructorForm())
      dispatch(initConstructorForm({ sections: task.content ? task.content : [] }))
    }
  }, [task])

  const saveContent = (event) => {
    if (event) {
      event.preventDefault()
    }

    dispatch(changeConstructorRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(updateTaskContent({ taskUuid, typeUuid: type.uuid, content: sections }))
    setRenamingSectionUuid(null)
  }

  const showUpdateColForm = (sectionIndex, colIndex, name, type, resultType) => {
    dispatch(
      setOpenUpdateColumnForm({
        openForm: `section-${sectionIndex}-column-${colIndex}`,
        name,
        type,
        resultType,
      }),
    )
  }

  const shouldBlockNavigation = () => {
    if (task.content !== sections) {
      setNavigateConfirmModal(true)
      return 'Are you sure you want to leave? You may lose unsaved changes.'
    }
    return null // Allow navigation
  }

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (task.content !== sections) {
        e.preventDefault()
        e.returnValue = '' // required for chrome
        return true // Return a truthy value
      }
      return null // Allow navigation if no conditions met
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [sections])

  return (
    <div className={'my-12 w-full'}>
      {sections.length === 0 && (
        <div className={'w-full text-center text-sm text-gray-500 py-8'}>There are no created sections yet.</div>
      )}
      {sections.length > 0 && (
        <div className={'my-4 grid gap-y-4'}>
          {sections.map((section, index) => (
            <div className={'w-full p-4 bg-gray-50 rounded-xl'} key={`section-${index}`}>
              {section.type === 'text' && (
                <>
                  <div className={'mb-4 flex flex-row justify-between'}>
                    <div className={'flex items-center text-sm text-gray-500'}>
                      <Bars3CenterLeftIcon className={'w-4 h-4 text-gray-500 mr-4'} />
                      {renamingSectionUuid === section.uuid && (
                        <RenameSectionForm section={section} sectionIndex={index} saveContent={saveContent} />
                      )}
                      {renamingSectionUuid !== section.uuid && (
                        <>
                          {section.name || 'Text section'}
                          <button
                            type={'button'}
                            onClick={(event) => {
                              setRenamingSectionUuid(section.uuid)
                            }}
                            className={'ml-1 p-1 rounded-md hover:bg-indigo-50'}
                          >
                            <PencilIcon className={'w-4 h-4 text-gray-400'} />
                          </button>
                        </>
                      )}
                    </div>
                    <div>
                      <Menu as="div" className="relative flex-none">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                              {({ focus }) => (
                                <button
                                  onClick={() => {
                                    deleteSectionProcess(index)
                                  }}
                                  className={classNames(
                                    focus ? 'bg-gray-50' : '',
                                    'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                                  )}
                                >
                                  Delete section
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <textarea
                    placeholder={'Please provide a task for the AI just here...'}
                    onChange={(event) => {
                      changeTextSectionTaskProcess(event, index)
                    }}
                    rows={8}
                    value={section.data.assignment || section.task}
                    className={'w-full rounded-md border-0 ring-1 text-sm text-gray-700'}
                    name={`section-${index}-task`}
                  />
                </>
              )}

              {section.type === 'list' && (
                <>
                  <div className={'mb-4 flex flex-row justify-between'}>
                    <div className={'flex items-center text-sm text-gray-500'}>
                      <BarsArrowDownIcon className={'w-4 h-4 text-gray-500 mr-4'} />
                      {renamingSectionUuid === section.uuid && (
                        <RenameSectionForm section={section} sectionIndex={index} saveContent={saveContent} />
                      )}
                      {renamingSectionUuid !== section.uuid && (
                        <>
                          {section.name || 'List section'}
                          <button
                            type={'button'}
                            onClick={(event) => {
                              setRenamingSectionUuid(section.uuid)
                            }}
                            className={'ml-1 p-1 rounded-md hover:bg-indigo-50'}
                          >
                            <PencilIcon className={'w-4 h-4 text-gray-400'} />
                          </button>
                        </>
                      )}
                    </div>
                    <div>
                      <Menu as="div" className="relative flex-none">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                              {({ focus }) => (
                                <button
                                  onClick={() => {
                                    deleteSectionProcess(index)
                                  }}
                                  className={classNames(
                                    focus ? 'bg-gray-50' : '',
                                    'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                                  )}
                                >
                                  Delete section
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <textarea
                    placeholder={'Please provide a task for the AI just here...'}
                    onChange={(event) => {
                      changeListSectionTaskProcess(event, index)
                    }}
                    value={section.data.assignment || section.task}
                    rows={8}
                    className={'w-full rounded-md border-0 ring-1 text-sm text-gray-700'}
                    name={`section-${index}-task`}
                  />
                </>
              )}

              {section.type === 'table' && (
                <>
                  <div className={'mb-4 flex flex-row justify-between'}>
                    <div className={'flex items-center text-sm text-gray-500'}>
                      <TableCellsIcon className={'w-4 h-4 text-gray-500 mr-4'} />
                      {renamingSectionUuid === section.uuid && (
                        <RenameSectionForm section={section} sectionIndex={index} saveContent={saveContent} />
                      )}
                      {renamingSectionUuid !== section.uuid && (
                        <>
                          {section.name || 'Table section'}
                          <button
                            type={'button'}
                            onClick={(event) => {
                              setRenamingSectionUuid(section.uuid)
                            }}
                            className={'ml-1 p-1 rounded-md hover:bg-indigo-50'}
                          >
                            <PencilIcon className={'w-4 h-4 text-gray-400'} />
                          </button>
                        </>
                      )}
                    </div>
                    <div>
                      <Menu as="div" className="relative flex-none">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                              {({ focus }) => (
                                <button
                                  onClick={() => {
                                    deleteSectionProcess(index)
                                  }}
                                  className={classNames(
                                    focus ? 'bg-gray-50' : '',
                                    'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                                  )}
                                >
                                  Delete section
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className={'space-y-4'}>
                    <div className={'flex flex-row'}>
                      <div
                        className={classNames(
                          `grid gap-2`,
                          section.data.cols.length > 0 && `w-full mr-4`,
                          section.data.cols.length === 1 && `grid-cols-1`,
                          section.data.cols.length === 2 && `grid-cols-2`,
                          section.data.cols.length === 3 && `grid-cols-3`,
                          section.data.cols.length === 4 && `grid-cols-4`,
                          section.data.cols.length === 5 && `grid-cols-5`,
                          section.data.cols.length === 6 && `grid-cols-6`,
                        )}
                      >
                        {section.data.cols.map((col, colIndex) => (
                          <div key={`column-${colIndex}`} className={'col-span-1 p-2 rounded-md ring-1 ring-gray-200'}>
                            <div className={'flex flex-row  justify-between items-center'}>
                              <div className={'flex flex-row items-center overflow-hidden'}>
                                <button
                                  type={'button'}
                                  onClick={(event) => {
                                    toggleColumnDetails(event, index, colIndex)
                                  }}
                                  className={
                                    'flex mr-2 w-5 h-5 hover:bg-indigo-50 rounded-md content-center items-center justify-center transition-all'
                                  }
                                >
                                  <ChevronDownIcon className={'w-4 h-4 text-gray-500'} />
                                </button>
                                <div className={'text-sm text-gray-700 font-semibold'}>{col.name}</div>
                              </div>
                              <Menu
                                as="div"
                                key={`col-options-${colIndex}-${(Math.random() + 1).toString(36).substring(7)}`}
                                className="relative flex-none"
                              >
                                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                  <span className="sr-only">Open options</span>
                                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                    <Menu.Item>
                                      {({ focus }) => (
                                        <button
                                          onClick={(event) => {
                                            showUpdateColForm(index, colIndex, col.name, col.type, col.resultType)
                                          }}
                                          className={classNames(
                                            focus ? 'bg-gray-50' : '',
                                            'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                                          )}
                                        >
                                          Update column
                                        </button>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ focus }) => (
                                        <button
                                          onClick={(event) => {
                                            deleteColProcess(event, index, colIndex)
                                          }}
                                          className={classNames(
                                            focus ? 'bg-gray-50' : '',
                                            'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                                          )}
                                        >
                                          Delete column
                                        </button>
                                      )}
                                    </Menu.Item>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                            {showColumnDetails.includes(`section-${index}-col-${colIndex}`) && (
                              <div className={'mt-2'}>
                                <div className={'text-xs text-gray-500'}>
                                  Content type: <span className={'font-semibold'}>{col.type}</span>
                                </div>
                                {col.type === 'ai-task' && (
                                  <div className={'text-xs text-gray-500'}>
                                    AI result type: <span className={'font-semibold'}>{col.resultType}</span>
                                  </div>
                                )}
                              </div>
                            )}
                            {showUpdateColumnForm === `section-${index}-column-${colIndex}` && (
                              <UpdateColumnForm section={section} sectionIndex={index} colIndex={colIndex} />
                            )}
                          </div>
                        ))}
                      </div>
                      {section.data.cols.length < 6 && <AddColumnForm section={section} sectionIndex={index} />}
                    </div>
                    {section.data.cols.length > 0 && (
                      <>
                        {section.data.rows.map((row, rowIndex) => {
                          const cells = row.cells || row

                          return (
                            <div key={`section-${index}-row-${rowIndex}`} className={'flex flex-row'}>
                              <div
                                key={`row-${rowIndex}`}
                                className={classNames(
                                  `grid gap-2`,
                                  section.data.cols.length > 0 && `w-full mr-4`,
                                  section.data.cols.length === 1 && `grid-cols-1`,
                                  section.data.cols.length === 2 && `grid-cols-2`,
                                  section.data.cols.length === 3 && `grid-cols-3`,
                                  section.data.cols.length === 4 && `grid-cols-4`,
                                  section.data.cols.length === 5 && `grid-cols-5`,
                                  section.data.cols.length === 6 && `grid-cols-6`,
                                )}
                              >
                                {cells.map((cell, rowColIndex) => (
                                  <button
                                    type={'button'}
                                    onClick={() =>
                                      openUpdateCellContentForm(
                                        index,
                                        rowIndex,
                                        rowColIndex,
                                        section.data.cols[rowColIndex].name,
                                        cell.assignment ?? '',
                                      )
                                    }
                                    className={
                                      'text-left flex items-start align-top col-span-1 rounded-md p-2 py-1 bg-white ring-1 ring-gray-200 text-sm text-gray-700 hover:bg-indigo-50 transition-all'
                                    }
                                    key={`row-${rowIndex}-col-${rowColIndex}`}
                                  >
                                    <div className={'line-clamp-3 overflow-hidden'}>
                                      {cell.assignment ?? (
                                        <span className={'text-gray-400'}>Put your content here...</span>
                                      )}
                                    </div>
                                  </button>
                                ))}
                              </div>
                              {section.data.cols.length < 6 && (
                                <div className={'w-14 flex items-center justify-center'}>
                                  <button
                                    type={'button'}
                                    onClick={(event) => {
                                      deleteRowProcess(event, index, rowIndex)
                                    }}
                                    className={
                                      'h-7 w-8 flex items-center justify-center rounded-md hover:bg-indigo-50 transition-all'
                                    }
                                  >
                                    <TrashIcon className={'w-4 h-4 text-gray-500'} />
                                  </button>
                                </div>
                              )}
                            </div>
                          )
                        })}
                        <div className={'flex flex-row'}>
                          <div
                            className={classNames(
                              `grid gap-2`,
                              section.data.cols.length > 0 && `w-full mr-4`,
                              section.data.cols.length === 1 && `grid-cols-1`,
                              section.data.cols.length === 2 && `grid-cols-2`,
                              section.data.cols.length === 3 && `grid-cols-3`,
                              section.data.cols.length === 4 && `grid-cols-4`,
                              section.data.cols.length === 5 && `grid-cols-5`,
                              section.data.cols.length === 6 && `grid-cols-6`,
                            )}
                          >
                            <div className={'col-span-1'}>
                              <button
                                type={'button'}
                                onClick={(event) => {
                                  addNewRowProcess(event, index)
                                }}
                                className={
                                  'w-full flex flex-row items-center p-2 rounded-md ring-1 ring-gray-200 text-sm text-gray-700 hover:bg-indigo-50 transition-all'
                                }
                              >
                                <PlusIcon className={'w-4 h-4 mr-2'} />
                                <span className={'font-semibold'}>Add new row</span>
                              </button>
                            </div>
                          </div>
                          {section.data.cols.length < 6 && <div className={'w-14'} />}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}
      <div className={'mb-32'}>
        <button
          type={'button'}
          onClick={() => {
            setShowTypes(true)
          }}
          className={classNames(
            'mx-auto block w-48 h-20 rounded-xl hover:bg-indigo-50 text-center items-center cursor-pointer ring-1 ring-gray-100 transition-all',
            showTypes ? 'bg-indigo-50 opacity-50' : 'bg-gray-50',
          )}
        >
          <div className={'w-full pt-3'}>
            <PlusIcon className={'mx-auto w-6 h-6 text-gray-700'} />
          </div>
          <div className={'text-sm text-gray-500 pt-2'}>Add section</div>
        </button>
        {showTypes && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowTypes(false)
            }}
          >
            <div className={'mx-auto mt-2 w-fit grid grid-cols-3 gap-4 bg-gray-50 rounded-xl p-4'}>
              {sectionTypes.map((type) => (
                <button
                  key={type.id}
                  type={'button'}
                  onClick={type.handler}
                  className={
                    'block w-32 p-4 ring-1 ring-gray-200 hover:bg-white cursor-pointer text-center text-sm rounded-md bg-gray-100 transition-all'
                  }
                >
                  <type.icon className={'mx-auto w-6 h-6 mb-2 text-gray-700'} />
                  <span className={'text-gray-500'}>{type.name}</span>
                </button>
              ))}
            </div>
          </OutsideClickHandler>
        )}
      </div>
      {/*{task.content !== sections && (*/}
      <button
        type={'button'}
        onClick={saveContent}
        className={
          'fixed flex items-center bottom-4 right-4 text-sm px-8 shadow-sm py-3 bg-indigo-600 text-white rounded-md font-semibold'
        }
      >
        {constructorRequestStatus === REQUEST_STATUSES.PENDING && <Loader />}
        Save content
      </button>
      {/*)}*/}
      <UnsavedDataNotification
        shouldBlockNavigation={shouldBlockNavigation}
        showNavigateConfirmModal={navigateConfirmModal}
        setNavigateConfirmModal={setNavigateConfirmModal}
      />
      <UpdateCellContent />
    </div>
  )
}

import React, { Fragment } from 'react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { classNames } from '../../../../utils/className'

export default function OutputStructureV1_0({ sections }) {
  return (
    <div className="text-gray-500 text-sm whitespace-pre-line">
      {sections &&
        sections.map((section, sectionIndex) => {
          return (
            <div key={`section-${sectionIndex}`} className={'border-b border-gray-100 mb-8'}>
              {section.contentType === 'text' && (
                <div className={'pb-8'}>
                  {section.name && <h3 className={'text-lg font-semibold text-indigo-600 pb-8'}>{section.name}</h3>}
                  <div className={'text-gray-700 text-sm leading-6'}>{section.value}</div>
                </div>
              )}
              {section.contentType === 'list' && (
                <div>
                  {section.name && <h3 className={'text-lg font-semibold text-indigo-600 pb-8'}>{section.name}</h3>}
                  {section.value.map((listItem, itemIndex) => (
                    <div key={`section-${sectionIndex}-list-item-${itemIndex}`} className={'pb-4 mb-4'}>
                      <h3 className={'text-base font-semibold text-gray-700 pb-2'}>{listItem.title}</h3>
                      <div className={'text-gray-700 text-sm leading-6'}>{listItem.content}</div>
                    </div>
                  ))}
                </div>
              )}

              {section.contentType === 'table' && (
                <div>
                  {section.name && (
                    <h3 className={'text-lg font-semibold text-indigo-600 py-4 pb-8'}>{section.name}</h3>
                  )}

                  <div
                    className={classNames(
                      'grid gap-2',
                      section.cols.length === 1 && 'grid-cols-1',
                      section.cols.length === 2 && 'grid-cols-2',
                      section.cols.length === 3 && 'grid-cols-3',
                      section.cols.length === 4 && 'grid-cols-4',
                      section.cols.length === 5 && 'grid-cols-5',
                      section.cols.length === 6 && 'grid-cols-6',
                    )}
                  >
                    {section.cols.map((col, colIndex) => (
                      <div
                        key={`section-${sectionIndex}-col-header-${colIndex}`}
                        className={'p-2 mb-2 bg-gray-50 rounded-md hover:bg-indigo-50'}
                      >
                        <div className={'text-gray-700 font-semibold text-sm leading-6'}>{col.name || col}</div>
                      </div>
                    ))}
                  </div>
                  {section.rows.map((row, rowIndex) => (
                    <div
                      key={`section-${sectionIndex}-row-${rowIndex}`}
                      className={classNames(
                        'grid gap-x-2 border-b border-gray-100 hover:bg-gray-50 rounded-md',
                        section.cols.length === 1 && 'grid-cols-1',
                        section.cols.length === 2 && 'grid-cols-2',
                        section.cols.length === 3 && 'grid-cols-3',
                        section.cols.length === 4 && 'grid-cols-4',
                        section.cols.length === 5 && 'grid-cols-5',
                        section.cols.length === 6 && 'grid-cols-6',
                      )}
                    >
                      {row.map((cell, cellIndex) => (
                        <div key={`section-${sectionIndex}-row-${rowIndex}-cell-${cellIndex}`} className={'p-2 mb-2'}>
                          {cell.responseType !== 'boolean' && cell.value}
                          {cell.responseType === 'boolean' && (
                            <>
                              {parseInt(cell.value) === 1 && (
                                <div className={'flex flex-row items-center'}>
                                  <div
                                    className={'w-6 h-6 bg-green-600 rounded-md flex items-center justify-center mr-2'}
                                  >
                                    <CheckIcon className={'w-4 h-4 text-white'} />
                                  </div>
                                  True
                                </div>
                              )}
                              {parseInt(cell.value) === 0 && (
                                <div className={'flex flex-row items-center'}>
                                  <div
                                    className={'w-6 h-6 bg-red-600 rounded-md flex items-center justify-center mr-2'}
                                  >
                                    <XMarkIcon className={'w-4 h-4 text-white'} />
                                  </div>
                                  False
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}

'use client'

import React, { useEffect, useState } from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from '../../../../../../common/outside-click-handler/OutsideClickHandler'
import {
  changeType,
  changeName as changeColumnName,
  changeResultType,
  resetForm as resetUpdateColumnForm,
  selectFormData as selectUpdateColumnFormData,
} from '../../../../../../../reducers/meeting-type-task/update-column'
import { updateColumn } from '../../../../../../../reducers/meeting-type-task/constructor'

export default function UpdateColumnForm({ section, sectionIndex, colIndex }) {
  const dispatch = useDispatch()

  const updateColumnFormData = useSelector(selectUpdateColumnFormData)

  const [updateColumnFormError, setUpdateColumnFormError] = useState(false)

  const closeUpdateColumnForm = () => {
    dispatch(resetUpdateColumnForm())
  }

  const updateColProcess = (event) => {
    event.preventDefault()

    if (updateColumnFormData.name.trim() === '') {
      setUpdateColumnFormError(true)
    } else {
      dispatch(
        updateColumn({
          sectionIndex,
          colIndex,
          column: {
            name: updateColumnFormData.name,
            type: updateColumnFormData.type,
            resultType: updateColumnFormData.resultType,
          },
        }),
      )
      dispatch(resetUpdateColumnForm())
      setUpdateColumnFormError(false)
    }
  }

  const changeColumnNameProcess = (event) => {
    if (event.target.value.trim() === '') {
      setUpdateColumnFormError(true)
    }
    dispatch(changeColumnName({ name: event.target.value }))
  }

  const changeColumnContentTypeProcess = (event) => {
    dispatch(changeType({ type: event.target.value }))
  }

  const changeColumnResultTypeProcess = (event) => {
    dispatch(changeResultType({ resultType: event.target.value }))
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  useEffect(() => {
    return () => {
      dispatch(resetUpdateColumnForm())
    }
  }, [])

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        closeUpdateColumnForm()
      }}
    >
      <form
        onSubmit={(event) => {
          updateColProcess(event)
        }}
        className={classNames(
          'absolute p-4 rounded-xl shadow ring-1 ring-gray-200 bg-white w-full max-w-80 mt-2',
          section.data.cols.length > 0 && '-ml-64',
        )}
      >
        <div>
          <input
            type={'text'}
            placeholder={updateColumnFormError ? 'Column name is required...' : 'Column name'}
            autoFocus={true}
            onChange={changeColumnNameProcess}
            value={updateColumnFormData.name}
            className={classNames(
              'w-full rounded-md border-none focus:outline-none focus:border-none ring-1 text-sm text-gray-700',
              updateColumnFormError ? 'ring-red-600 focus:ring-red-600' : '',
            )}
            name={'task-name'}
          />
        </div>
        <div className={'mt-2'}>
          <label htmlFor="location" className="block text-xs leading-6 text-gray-500">
            Content type
          </label>
          <select
            id="location"
            name="content-type"
            defaultValue={updateColumnFormData.type}
            onChange={changeColumnContentTypeProcess}
            className="mt-1 text-sm block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:leading-6"
          >
            <option value={'text'}>Plain text</option>
            <option value={'ai-task'}>AI task</option>
          </select>
        </div>
        {updateColumnFormData.type === 'ai-task' && (
          <div className={'mt-2'}>
            <label htmlFor="location" className="block text-xs leading-6 text-gray-500">
              AI response type
            </label>
            <select
              id="location"
              name="response-type"
              defaultValue={updateColumnFormData.resultType}
              onChange={changeColumnResultTypeProcess}
              className="mt-1 text-sm block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:leading-6"
            >
              <option value={'text'}>Plain text</option>
              <option value={'boolean'}>Boolean</option>
            </select>
          </div>
        )}
        <div className={'mt-4'}>
          <button
            type={'submit'}
            className={classNames(
              'ml-auto flex items-center rounded-md bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
            )}
          >
            <CheckIcon className={'w-4 h-4 text-gray-700 mr-2'} />
            Save changes
          </button>
        </div>
      </form>
    </OutsideClickHandler>
  )
}
